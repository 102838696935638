<template>
  <div>
    <v-row>
      <v-col>
        <v-subheader>single</v-subheader>
        {{date}}
        <custom-date-picker v-model="date" :disabled="!true"></custom-date-picker>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row>
      <v-col>
        <v-subheader>beginDate</v-subheader>
        {{beginDate}}
        <custom-date-picker v-model="beginDate" :max="endDate"></custom-date-picker>
      </v-col>
      <v-col>
        <v-subheader>endDate</v-subheader>
        {{endDate}}
        <custom-date-picker v-model="endDate" :min="beginDate"></custom-date-picker>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row>
      <v-col>
        <v-subheader>month</v-subheader>
        {{period}}
        <custom-date-picker v-model="period" type="month"></custom-date-picker>
      </v-col>
    </v-row>
    <v-divider></v-divider>

    <v-row>
      <v-col>
        <v-subheader>beginPeriod</v-subheader>
        {{beginPeriod}}
        <custom-date-picker v-model="beginPeriod" :max="endPeriod" type="month"></custom-date-picker>
      </v-col>
      <v-col>
        <v-subheader>endPeriod</v-subheader>
        {{endPeriod}}
        <custom-date-picker v-model="endPeriod" :min="beginPeriod" type="month"></custom-date-picker>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      date: "",
      beginDate: "",
      endDate: "",

      period: "",
      beginPeriod: "",
      endPeriod: ""
    };
  },
  created() {}
};
</script>

